import { useState } from "react";
import { connect } from "react-redux";

import { Button, Card, Drawer, Typography } from "@material-ui/core"
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";
import styled from 'styled-components'
import { DrawerTitle } from "../../elementos/drawer-title";
import { ComprarCreditosCliente } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";


const ContainerCreditosCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `


  width: 350px;
  position: relative;
  height: 100%;

  ${theme.breakpoints.down('sm')} {
    width: 250px;
  }

  .paquetes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      
    }
  }

  .paquete-credito {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    ${theme.breakpoints.down('sm')} {
    }
  }

  .paquete-credito: hover {
    background-color: #FEF9E7;
    
  }

  .paquete-credito-selected {
    background-color: #FCF3CF;
  }

  .cliente-nombre-container {
    padding: 10px;
    margin-bottom: 10px;
    height: 60px;
  }

  .clientenombre-container-selected {
    background-color: #D5F5E3;
  }

  .cliente-nombre {
    text-align: center;
  }

  .button-credito-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    padding: 10px;
  }

  .action-credito-button {
    margin-top: 20px;
    height: 80px;
  }

`}
`


const CreditosCliente = (props) => {

  const { openDrawer, selectedCliente, profile } = props

  const [selectedPaquete, setSelectedPaquete] = useState(null)

  const handleCloseDrawer = () => {
    setSelectedPaquete(null)
    props.handleCloseDrawer()
  }

  const handleSelectPaquete = (paquete) => {
    setSelectedPaquete(paquete)
  }

  const handleComprarPaquete = async() => {
    props.SendMenssageAction({option: 'inicio'})
    const comprar = await props.ComprarCreditosCliente({cliente: selectedCliente, paquete: selectedPaquete})
    if(comprar?.exito){
      handleCloseDrawer()
    }
    props.SendMenssageAction(comprar)
  }

  let paqueteIsSelected = ''

  if(selectedPaquete){
    paqueteIsSelected = 'clientenombre-container-selected'
  }


  const paquetes = [
    {
      creditos: '1',
      costo: '200',
      vigencia: 5
    },
    {
      creditos: '5',
      costo: '900',
      vigencia: 15
    },
    {
      creditos: '10',
      costo: '1700',
      vigencia: 20
    },
    {
      creditos: '15',
      costo: '2300',
      vigencia: 25
    },
    {
      creditos: '20',
      costo: '2850',
      vigencia: 30
    },
  ]
  return (
    <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
      <ContainerCreditosCliente>

        <DrawerTitle title={"Pquetes de creditos"} handleCloseDrawer={handleCloseDrawer} />

        {profile?.roll === 'cliente' ?
        <Box className="paquetes-container">
          {paquetes.map(paquete => {

            return (
              <Card variant="outlined" className={`paquete-credito`} 
              key={paquete?.creditos} 
              >
                <Typography>
                  Creditos: {paquete?.creditos}
                </Typography>
                <Typography>
                  $ {parseFloat(paquete?.costo).toFixed(2)}
                </Typography>
              </Card>
            )
          })}
        </Box>
        :
        <Box className="paquetes-container">
          {paquetes.map(paquete => {

            let selected = ''
            if(selectedPaquete && selectedPaquete?.creditos === paquete?.creditos){
              selected = 'paquete-credito-selected'
            }
            return (
              <Card variant="outlined" className={`paquete-credito ${selected}`} 
              key={paquete?.creditos} 
              onClick={() => handleSelectPaquete(paquete)}
              >
                <Typography>
                  Creditos: {paquete?.creditos}
                </Typography>
                <Typography>
                  $ {parseFloat(paquete?.costo).toFixed(2)}
                </Typography>
              </Card>
            )
          })}
        </Box>
        }

        {profile?.roll === 'cliente' ?
        null
        :
        <Box className={'button-credito-container'}>
          <Box className={`cliente-nombre-container ${paqueteIsSelected}`} >
            <Typography className="cliente-nombre">
              {`${selectedCliente?.nombre} ${selectedCliente?.apellidos}`}
            </Typography>
            {selectedPaquete ?
            <>
              <Typography className="cliente-nombre">
                Creditos: {selectedPaquete?.creditos}
              </Typography>
              <Typography className="cliente-nombre">
                $ {parseFloat(selectedPaquete?.costo).toFixed(2)}
              </Typography>
            </>
            :
            null
            }
          </Box>
          <Button fullWidth className='action-credito-button' variant='contained' onClick={handleComprarPaquete}>
            Comprar
          </Button>
        </Box>
        }

        
      </ContainerCreditosCliente>

    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile, 
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  ComprarCreditosCliente: (datos) => dispatch(ComprarCreditosCliente(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(CreditosCliente);


import ListItem from "@material-ui/core/ListItem";
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import Tooltip from '@material-ui/core/Tooltip';

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Divider } from "@material-ui/core";

const ContainerCliente = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

  .list-item-cliente {
    background-color: #D5F5E3;
  }

  .list-item-cliente: hover {
    background-color: #ABEBC6;
  }

  .divider-color {
    background-color: #FFFFFF;
    height: 2px;
  }

`}
`

export const Cliente = (props) => {

  const { cliente, handleOpenDrawer } = props

  const nombre = `${cliente?.nombre} ${cliente?.apellidos}`
  return (
    <ContainerCliente>
      <ListItem button className="list-item-cliente">
        <ListItemText
          primary={nombre}
          secondary={`creditos: ${cliente?.creditos}`}
        />
        <ListItemSecondaryAction>
          <Tooltip arrow title="Agregar creditos">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'creditos',  cliente: cliente})} >
              <BlurCircularIcon fontSize="small" />
            </IconButton>                  
          </Tooltip>
          <Tooltip arrow title="Editar cliente">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'editar', cliente: cliente})} >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Cambiar contraseña">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'password', cliente: cliente})} >
              <LockIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider className="divider-color" />
    </ContainerCliente>
  )
}
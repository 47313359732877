import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";
import { SearchIcon } from '@material-ui/icons/Search';
import styled from 'styled-components'

import { RegistrarClienteAction, EditarClienteAction } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";

import { esNumero, getAllMonths, getAllDays } from '../../allFunctions'
import { DrawerTitle } from "../../elementos/drawer-title";
const ContainerRegistrarCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `
  width: 350px;
  position: relative;
  height: 100%;
  padding: 10px;
  margin-top: 10px;

  .input-form-user {
    margin-bottom: 20px;
  }

  .button-registrar-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .action-registrar-button {
    height: 80px;
    margin-bottom: 20px;
  }

  .phone-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-emergencyphone {
    margin-left: 5px;
  }

  .date-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-daydate {
    margin-left: 5px;
  }

`}
`
const RegistrarCliente = (props) => {

  const { openDrawer, selectedCliente } = props
  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [telefonoEmergencia, setTelefonoEmergencia] = useState('')
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState('')
  const [monthBirthday, setMonthBirthday] = useState(0)
  const [dayBirthday, setDayBirthday] = useState(1)

  const handleCloseDrawer = () => {
    props.handleCloseDrawer()
    cleanRegisterData()
  }

  useEffect(() => {

    if(selectedCliente){
      setNombres(selectedCliente?.nombre)
      setApellidos(selectedCliente?.apellidos)
      setEmail(selectedCliente?.email)
      setTelefono(selectedCliente?.telefono)
      setTelefonoEmergencia(selectedCliente?.telefonoEmergencia)
      setMonthBirthday(selectedCliente?.monthBirthday)
      setDayBirthday(selectedCliente?.dayBirthday)
    }

  },[selectedCliente])

  const handleChange = (e) => {
    const name = e.target.name
    const valor = e.target.value

    if(name === 'nombres'){
      setNombres(valor)
    }
    if(name === 'apellidos'){
      setApellidos(valor)
    }
    if(name === 'email'){
      setEmail(valor)
    }
    if(name === 'telefono'){

      if(!esNumero(valor) || valor.length > 10) return 
      setTelefono(valor)
    }
    if(name === 'telefonoEmergencia'){
      if(!esNumero(valor) || valor.length > 10) return 
      setTelefonoEmergencia(valor)
    }
    if(name === 'month'){
      setMonthBirthday(valor)
    }
    if(name === 'day'){
      setDayBirthday(valor)
    }
    if(name === 'usuario'){
      setUsuario(valor)
    }
    if(name === 'password'){
      setPassword(valor)
    }
  }

  const cleanRegisterData = () => {
      setNombres('')
      setApellidos('')
      setEmail('')
      setTelefono('')
      setTelefonoEmergencia('')
      setMonthBirthday('Enero')
      setDayBirthday(0)
      setUsuario('')
      setPassword('')
  }

  const handleRegistrarCliente = async () => {

    const data = {
      nombre: nombres,
      apellidos: apellidos,
      email: email,
      telefono: telefono,
      telefonoEmergencia: telefonoEmergencia,
      monthBirthday: monthBirthday,
      dayBirthday: dayBirthday,
      usuario: usuario,
      password: password
    }

    props.SendMenssageAction({option: 'inicio'})
    const guardar = await props.RegistrarClienteAction(data)
    if(guardar?.exito){
      cleanRegisterData()
      props.handleCloseDrawer()
    }
    props.SendMenssageAction(guardar)

  }

  const handleEditarCliente = async () => {
    const data = {
      id: selectedCliente?.id,
      nombre: nombres,
      apellidos: apellidos,
      email: email,
      telefono: telefono,
      telefonoEmergencia: telefonoEmergencia,
      monthBirthday: monthBirthday,
      dayBirthday: dayBirthday,
    }

    props.SendMenssageAction({option: 'inicio'})
    const editar = await props.EditarClienteAction(data)
    if(editar?.exito){
      cleanRegisterData()
      props.handleCloseDrawer()
    }
    props.SendMenssageAction(editar)
  }

  const allMonths = getAllMonths()
  const allDays = getAllDays()
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >

        <DrawerTitle title={selectedCliente ? 'Editar Cliente' : 'Registrar Cliente'} handleCloseDrawer={handleCloseDrawer} />
        
        <ContainerRegistrarCliente >
          <TextField
            label="Nombre"
            name="nombres"
            value={nombres}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
          <TextField
            label="Apellidos"
            name="apellidos"
            value={apellidos}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />

          <Box className="date-container">
            <FormControl variant="outlined" fullWidth className="input-form-user">
              <InputLabel htmlFor="outlined-age-native-simple">Mes de nacimiento</InputLabel>
                <Select
                  native
                  value={monthBirthday}
                  onChange={handleChange}
                  label="Mes de nacimiento"
                  inputProps={{
                    name: 'month',
                  }}
                >
                  {allMonths.map(month => {
                    return (
                      <option key={month?.id} value={month.mes} >{month.mes}</option>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="input-form-user input-daydate" fullWidth>
              <InputLabel htmlFor="outlined-age-native-simple">Dia de nacimiento</InputLabel>
                <Select
                  native
                  value={dayBirthday}
                  onChange={handleChange}
                  label="Dia de nacimiento"
                  inputProps={{
                    name: 'day',
                  }}
                >
                  {allDays.map(day => {
                    return (
                      <option key={day} value={day} >{day}</option>
                    )
                  })}
              </Select>
            </FormControl>
          </Box>

          <TextField
            label="Correo"
            name="email"
            value={email}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
          <Box className="phone-container">
            <TextField
              label="Telefono"
              name="telefono"
              value={telefono}
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              className="input-form-user"
            />
            <TextField
              label="TelefonoEmergencia"
              name="telefonoEmergencia"
              value={telefonoEmergencia}
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              className="input-form-user input-emergencyphone "
            />
          </Box>
          {selectedCliente ?
          null
          :
          <TextField
            label="Usuario"
            name="usuario"
            value={usuario}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
          }
          {selectedCliente ?
          null
          :
          <TextField
            label="Contraseña"
            name="password"
            value={password}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
          }

          <Box className='button-registrar-container'>
            <Button fullWidth className='action-registrar-button' variant='contained' onClick={selectedCliente ? handleEditarCliente : handleRegistrarCliente} >
                  {selectedCliente ? 'Editar clienmte' : 'Registrar cliente'}
            </Button>
          </Box>


        </ContainerRegistrarCliente>
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  RegistrarClienteAction: (datos) => dispatch(RegistrarClienteAction(datos)),
  EditarClienteAction: (datos) => dispatch(EditarClienteAction(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(RegistrarCliente);
import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { CardTitle } from '../../elementos/card-title'
import { Card, Container, Dialog } from '@material-ui/core';
import { getFecha } from '../../allFunctions'
import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CalendarioHorarios from './CalendarioHorarios'
import LugaresDrawer from './LugaresDrawer'
import LugaresDrawer2 from './LugaresDrawer2'
import { green, red } from '@material-ui/core/colors';

// import { motion } from "framer-motion"
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion"
import moment from 'moment'
const ContainerCalendario = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

    .horarios-container {
      width: 100%;
      padding: 10px;
    }
    
    .horario-dia {
      width: 100%;
      padding: 10px;
      background-color: ${green["A100"]};
      margin-bottom: 5px;
      cursor: pointer;
    }
    
    .horario-dia:hover {
      background-color: ${green["A400"]};
      
    }
    
    .horario-selected {
      background-color: ${green["A400"]};

    }

    .calendar-card-container {
      height: 100%;
      border-radius: 15px 15px 10px 10px;
    }

    .motion-day-container {
      width: calc(100% / 4 - 20px);
      height: 150px;
      border: none;
      cursor: pointer;
      margin: 10px;
      border-radius: 15px 15px 10px 10px;
      padding: 0px;
      ${theme.breakpoints.down('sm')} {
        height: 80px;
      }
    }

    .calendar-day-month {
      background-color: ${red["A200"]};
      color: #fff;
      font-size: 20px;
      ${theme.breakpoints.down('sm')} {
        font-size: 13px;
      }

    }

    .calendar-day-container {
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${theme.breakpoints.down('sm')} {
        height: 60px;
      }
    }

    .calendar-day-num {
      font-size: 45px;
      ${theme.breakpoints.down('sm')} {
        font-size: 25px;
      }
    }

    .calendar-day {
      font-size: 18px;
      ${theme.breakpoints.down('sm')} {
        font-size: 12px;
      }
    }

    .horarios-day-container {
      height: 300px;
    }

    


`}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CalendarDays = (props) => {

  const { FScalendarioData, profile } = props
  const [fechaCalendarioSelected, setFechaCalendarioSelected] = useState(null)
  const [horariosSelected, setHorariosSelected] = useState(null)
  const [openHorario, setOpenHorario] = useState(false)
  const [openDayTimeDialog, setOpenDayTimeDialog] = useState(false)
  const [selectedCliente, setSelectedCliente] = useState(null)


  useEffect(() => {

    if(profile?.roll === 'cliente'){
      setSelectedCliente(profile)
    }
  
    return () => {
    }
  }, [profile])

  useEffect(() => {
    
    if(fechaCalendarioSelected && FScalendarioData){
      const fechaSelected = FScalendarioData.find(fechaCalendar => fechaCalendar?.id === fechaCalendarioSelected?.id)
      setFechaCalendarioSelected(fechaSelected)
    }
    
  }, [FScalendarioData])
  

  const handleOpenDayTimeDialog = (fechaCalendario) => {
    setOpenDayTimeDialog(true)
    setFechaCalendarioSelected(fechaCalendario)

  }

  const handleCloseDayTimeDialog = () => {
    setOpenDayTimeDialog(false)
    setFechaCalendarioSelected(null)

  }

  const handleOpenHorario = (horarios) => {

    setHorariosSelected(horarios)
    setOpenHorario(true)
  }

  const handleCloseHorario = () => {
    setHorariosSelected(null)
    setOpenHorario(false)
  }

  if(!FScalendarioData || FScalendarioData?.length === 0) return null

  return (
    <ContainerCalendario>

        <CardTitle title="Calendario" />

        <div>
          {FScalendarioData.map(fechaCalendario => {

            const month  = getFecha(fechaCalendario.fecha.seconds, 6)
            const dayNum  = getFecha(fechaCalendario.fecha.seconds, 7)
            const day  = getFecha(fechaCalendario.fecha.seconds, 8)

             return (
              <>

               <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="motion-day-container"
                onClick={() => handleOpenDayTimeDialog(fechaCalendario)}
              >
               <Card className="calendar-card-container" variant="outlined">
                  <Typography className='calendar-day-month'>
                  {month}
                  </Typography>
                <Box className="calendar-day-container">
                  <Box>
                    <Typography className='calendar-day-num'>
                      {dayNum}
                    </Typography>
                    <Typography className='calendar-day'>
                      {day}
                    </Typography>
                  </Box>
                </Box>
                </Card>
              </motion.button>

             
              </>
             )
          })}
        </div>

        <CalendarioHorarios 
        openDayTimeDialog={openDayTimeDialog}
        handleCloseDayTimeDialog={handleCloseDayTimeDialog}
        fechaCalendarioSelected={fechaCalendarioSelected}
        handleOpenHorario={handleOpenHorario}
        />

      <LugaresDrawer 
        fechaCalendarioSelected={fechaCalendarioSelected} 
        horariosSelected={horariosSelected} 
        openHorario={openHorario} 
        handleCloseHorario={handleCloseHorario} 
        selectedCliente={selectedCliente}
      />


    </ContainerCalendario>
  );
}

const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FScalendarioData: state.calendarioReducer.calendarioData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // UpdateCalendarioFecha: (datos) => dispatch(UpdateCalendarioFecha(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDays)

import { useState } from 'react';
import { connect } from 'react-redux'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import { Box, Button, Card, Typography } from '@material-ui/core';
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel';
import { getFecha, checkUserHasReservationInDay, checkUserCanCancelReservation } from '../../allFunctions'
import { StyledBox } from '../../elementos/styled-box/StyledBox';
import { DrawerTitle } from '../../elementos/drawer-title';
import Swal from 'sweetalert2'
import { SetReservePlaceDateByUser, SetCancelReservePlaceDateByUser } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";
import { green, red } from '@material-ui/core/colors'
import { LugaresCliente } from './LugaresCliente';
const CalendarioHorarioContainer = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

      width: 350px;
      position: relative;
      height: 100%;
      
      ${theme.breakpoints.down('sm')} {
        width: 250px;
      }

      .header-title {
        margin-right: 20px;
      }

      .header-toolbar {
        flex: 1
      }

      .title-dialog-desktop {
        display: flex;
        align-items: center;
      }

      .lugares-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        ${theme.breakpoints.down('sm')} {
          flex-direction: column;
          
        }
      }

      .pilate-cama {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        ${theme.breakpoints.down('sm')} {
          height: 60px;
        }
      }

      .pilate-cama-selected {
        background-color: #FCF3CF;
      }

      .pilate-camaocupado-cliente {
        background-color: ${green["300"]};
      }

      .pilate-cama-ocupado {
        background-color: #ff6961;
      }

      .button-lugar-container {
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        padding: 10px;
      }

      .action-lugar-button {
        margin-top: 20px;
        height: 80px;
      }

      .lugar-button-reservar {
        background-color: ${green["300"]};
      }

      .lugar-button-cancelar {
        background-color: ${red["400"]};
      }

  `}
`

const SwipeableTemporaryDrawer = (props) => {

  const { fechaCalendarioSelected, horariosSelected, openHorario, selectedCliente, profile } = props
  const [selectedLugar, setSelectedLugar] = useState(null)

  const handleSelectLugar = (lugar) => {
    setSelectedLugar(lugar)
  }

  const handleCloseHorario = () => {
    setSelectedLugar(null)
    props.handleCloseHorario()
  }

  const handleSetLugarHorario = async () => {

    if(selectedCliente?.creditos <= 0){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No cuentas con creditos</strong>',
        text: `Realiza una compra para poder reservar`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    console.log('fechaCalendarioSelected', fechaCalendarioSelected)
    console.log('selectedCliente', selectedCliente)


    const hasReservation = checkUserHasReservationInDay(fechaCalendarioSelected, selectedCliente)

    console.log('hasReservation', hasReservation)
    if(hasReservation){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>Ya tienes reservación</strong>',
        text: hasReservation,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    // console.log('fechaCalendarioSelected', fechaCalendarioSelected)
    // console.log('horariosSelected', horariosSelected)
    // console.log('selectedLugar', selectedLugar)
    // console.log('profile', profile)
    props.SendMenssageAction({option: 'inicio'})

    const reservar = await props.SetReservePlaceDateByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: selectedLugar?.id,
        cliente: selectedCliente
      }
    )

    if(reservar?.exito){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Reservación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(reservar)
  }

  const handleCancelReservation = async () => {

    console.log('fechaCalendarioSelected', fechaCalendarioSelected)
    console.log('horariosSelected', horariosSelected)
    console.log('selectedCliente', selectedCliente)

    const canCancel = checkUserCanCancelReservation(fechaCalendarioSelected, horariosSelected, selectedCliente)

    if(!canCancel){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No es posinle cancelar</strong>',
        html: 'Solo es posible cancelar con <strong>6 hrs.</strong> de anticipación',
        confirmButtonColor: '#3085d6',
      })
      return
    }

    let lugar = horariosSelected?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id)

    props.SendMenssageAction({option: 'inicio'})

    const cancel = await props.SetCancelReservePlaceDateByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: lugar?.id,
        cliente: selectedCliente
      }
    )

    if(cancel?.exito){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Cancelación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(cancel)

  }

  const fecha  = getFecha(fechaCalendarioSelected?.fecha?.seconds, 9)
  const hasReserve = horariosSelected?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id)

  return (
    <div>
          <Drawer
            anchor={'right'}
            open={openHorario}
            onClose={handleCloseHorario}
          >
          <CalendarioHorarioContainer >

              <DrawerTitle title={fecha} title2={horariosSelected?.id} handleCloseDrawer={handleCloseHorario} />

            <LugaresCliente 
            horariosSelected={horariosSelected}
            selectedCliente={selectedCliente}
            selectedLugar={selectedLugar}
            handleSelectLugar={handleSelectLugar}
            handleSetLugarHorario={handleSetLugarHorario}
            handleCancelReservation={handleCancelReservation}
            hasReserve={hasReserve}
            />

            {/* <Box className="lugares-container">
              {horariosSelected?.lugares.map(lugar => {

                let selected = ''
                if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
                  console.log('lugar', lugar)
                  selected = 'pilate-camaocupado-cliente'
                }else if(lugar?.ocupado){
                  selected = 'pilate-cama-ocupado'

                }else if(selectedLugar && selectedLugar?.id === lugar.id){
                  selected = 'pilate-cama-selected'
                }
                return (
                  <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} onClick={lugar?.ocupado || hasReserve ? null : () => handleSelectLugar(lugar)}>
                    <Typography>
                      Cama {lugar?.id}
                    </Typography>
                  </Card>
                )
              })}

            </Box>

            <Box className='button-lugar-container'>
              <Button fullWidth className='action-lugar-button' 
              variant='contained' 
              onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
              disabled={!selectedLugar && !hasReserve}
              >
                {selectedLugar ? 'Reservar' : hasReserve ? 'Cancelar' : 'Accion'}
              </Button>
            </Box> */}

           </CalendarioHorarioContainer>
          </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FScalendarioData: state.calendarioReducer.calendarioData
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      SetReservePlaceDateByUser: (datos) => dispatch(SetReservePlaceDateByUser(datos)),
      SetCancelReservePlaceDateByUser: (datos) => dispatch(SetCancelReservePlaceDateByUser(datos)),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
      // logout: (datos) => dispatch(logout(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwipeableTemporaryDrawer)
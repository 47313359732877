import { connect } from 'react-redux'

import { Avatar, Button, Card, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { getFecha } from '../../allFunctions';
import { red, green } from '@material-ui/core/colors'
import CreditosCliente from '../menuComercio/CreditosCliente';
import { useState } from 'react';
const ContainerProfileCliente = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `


margin-top: 80px;

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .logo {
    width: 150px;
    height: 150px;
  }

  .user-info-container {
    text-align: center;
  }

  .cliente-name {
    font-size: 18px;
    font-weight: bold;
  }

  .creditos-color {
    color: ${green["400"]};

  }

  .creditos-color-none {
    color: ${red["900"]};
  }

  .recent-class {
    text-align: center;
    font-size: 18px;
  }

  .reservation-classes-container {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }

  .clase-horario {
    font-size: 16px;
  }

  .proximas-clases {
    font-size: 18px;
    font-weight: bold;
  }

  .action-password-button {
  }

`}
`;



const ProfileCliente = (props) => {

  const { profile, FSlugaresReservados } = props

  const [openDrawer, setOpenDrawer] = useState(false)


  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }


  console.log('FSlugaresReservados', FSlugaresReservados)

  let creditosColor = 'creditos-color'
  if(profile?.creditos === 0){
    creditosColor = 'creditos-color-none'
  }

  return (
    <ContainerProfileCliente>

        <Box className='logo-container'>
        <Avatar alt="Logo" src="/static/images/avatar/1.jpg" className="logo" />
        </Box>

        <Box className='user-info-container mb-8'>
          <Typography className='cliente-name'>
            {profile?.nombre}
          </Typography>
          <Typography className='cliente-name'>
            {profile?.apellidos}
          </Typography>
          <Typography className={`cliente-name ${creditosColor}`}>
            Creditos: {profile?.creditos}
          </Typography>

        </Box>

        <Typography className='ml-2 proximas-clases'>
        Próximas clases
        </Typography>

        <Divider className='mv-1 mh-2' />

          {FSlugaresReservados && FSlugaresReservados.slice(0,3).map(lugarReservado => {
            
            return (
              <Box className="reservation-classes-container">
                <Box flexGrow={1}>
                    <Typography  className='clase-horario'>
                    {getFecha(lugarReservado?.idHorario, 9)}
                    </Typography>
                </Box>
                <Box className='mr-2'>
                    <Typography className='clase-horario'>
                    {getFecha(lugarReservado?.idHorario, 4)}
                    </Typography>
                </Box>
                <Box className='ml-2'>
                    <Typography className='clase-horario-time'>
                    Cama {lugarReservado?.idLugar}
                    </Typography>
                </Box>
            </Box>

            )
          })}

        <Divider className='mv-1 mh-2' />

        <Button fullWidth className='action-password-button mt-2' variant='contained' color='primary'
          onClick={() => setOpenDrawer(true)} 
        >
            Paquetes de creditos
        </Button>

        <CreditosCliente
          openDrawer={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
        />

    </ContainerProfileCliente>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FSlugaresReservados: state.storageDataReducer.FSlugaresReservados
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // logout: (datos) => dispatch(logout(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCliente)

import { useState } from 'react';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';

import { Cliente } from "./Cliente";
import RegistrarCliente from './RegistrarCliente';
import CreditosCliente from './CreditosCliente';
import ClientePassword from './ClientePassword';


export const Clientes = (props) => {

  const { clientes } = props
  
  const [selectedCliente, setSelectedCliente] = useState(null)
  const [openEditarCliente, setOpenEditarCliente] = useState(false)
  const [openCreditosCliente, setOpenCreditosCliente] = useState(false)
  const [openPasswordCliente, setOpenPasswordCliente] = useState(false)

  const handleOpenDrawer = ({opcion, cliente}) => {
    console.log('opcion', opcion)
    console.log('cliente', cliente)
    setSelectedCliente(cliente)
    if(opcion === 'creditos'){
      setOpenCreditosCliente(true)
    }else if(opcion === 'editar'){
      setOpenEditarCliente(true)
    }else if(opcion === 'password'){
      setOpenPasswordCliente(true)
    }
  }

  const handleCloseCliente = () => {
    setSelectedCliente(null)
    setOpenEditarCliente(false)
    setOpenCreditosCliente(false)
    setOpenPasswordCliente(false)
  }

  return (
    <>
    <List>
      {clientes && clientes.map(cliente => {
        return (
          <Cliente 
          key={cliente?.id} 
          cliente={cliente} 
          handleOpenDrawer={handleOpenDrawer} 
          />
        )
      })}     
    </List>
    <CreditosCliente openDrawer={openCreditosCliente} selectedCliente={selectedCliente} handleCloseDrawer={handleCloseCliente} />
    <RegistrarCliente openDrawer={openEditarCliente} selectedCliente={selectedCliente} handleCloseDrawer={handleCloseCliente} />
    <ClientePassword openDrawer={openPasswordCliente} selectedCliente={selectedCliente} handleCloseDrawer={handleCloseCliente} />

    </>

  )
}
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//* COMPONENTES
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";

//* MATERIAL UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import ListItem from "@material-ui/core/ListItem";
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import BlockIcon from "@material-ui/icons/Block";
import Divider from "@material-ui/core/Divider";
import { InputAdornment, TextField, Container } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/styles";
import styled from "styled-components";
import ListaIngredientes from "./ListaIngredientes";

import RegistrarCliente from "./RegistrarCliente";
import { SendMenssageAction } from "../../store/actions/messageActions";
import {
  GuardarCategoriasMenu,
  CancelActivarIngredientes,
} from "../../store/actions/categoriaMenuActions";
import Categorias from "./Categorias";
import Images from "./Images";
import { CardTitle } from "../../elementos/card-title";
import { StyledBox } from "../../elementos/styled-box/StyledBox";
import { Clientes } from './Clientes';
import UserCalendar from "../cliente/calendario/UserCalendar";
import ProfileCliente from '../profile/ProfileCliente'


const ContainerClientes = styled(({ ...props }) => <Container {...props} />)`
  ${({ theme }) => `

    margin-top: 80px;

    .card-container {
        padding: 10px;
        height: calc(100vh - 150px)
    }

    .burcar-cliente-container {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: end;
    }

    .input-buscar-usuario {
        margin-top: 10px;
    }

    .button-registrar-usuario {
        height: 53px;
        margin-left: 10px;
    }

`}
`;

const styles = (theme) => ({
  disabled: {
    backgroundColor: "#ef5350",
  },
  enabled: {
    backgroundColor: "#26a69a",
  },
  fixedDown: {
    position: "fixed",
    top: "auto",
    bottom: 0,
    width: "100%",
    color: "#ffffff",
    maxWidth: 720,
  },
  styleContenedor: {
    margin: "auto",
    maxWidth: 720,
    marginTop: 80,
    [theme.breakpoints.down("xs")]: {
      marginTop: 60,
    },
  },
});

function MenuComercio(props) {

  const { profile } = props
  const [comercio, setComercio] = useState(null);
  const [categoriasMenu, setCategoriasMenu] = useState(null);
  const [menuIngredientes, setMenuIngredientes] = useState(null);
  const [openMenuIngredientes, setOpenMenuIngredientes] = useState(false);
  const [menuBloqueado, setMenuBloqueado] = useState(false);
  const [showGuardarCambios, setShowGuardarCambios] = useState(false);
  const [openRegistrarUsuario, setOpenRegistrarUsuario] = useState(false);
  const [searchUsuario, setSearchUsuario] = useState('');

  const handleBuscarUsuario = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    const valor = e.target.value

    setSearchUsuario(valor)

  };

  const handleOpenRegistrarUsuario = () => {
    setOpenRegistrarUsuario(true)
  }

  const handleCloseRegistrarUsuario = () => {
    setOpenRegistrarUsuario(false)
  }

  useEffect(() => {
    setCategoriasMenu(props.categoriasMenu);
  }, [props.categoriasMenu]);

  useEffect(() => {
    setMenuIngredientes(props.FSmenuIngredientes);
  }, [props.FSmenuIngredientes]);

  useEffect(() => {
    setMenuBloqueado(props.menuBloqueado);
  }, [props.menuBloqueado]);

  useEffect(() => {
    setShowGuardarCambios(props.mostrarGuardarCatMenu);
  }, [props.mostrarGuardarCatMenu]);

  useEffect(() => {
    props.FScomercio &&
      props.FScomercio.map((FScomercio) => {
        setComercio(FScomercio);
      });
  }, [props.FScomercio]);

  const handleGuardarCategoriasMenu = async () => {
    props.SendMenssageAction({ option: "inicio" });
    const guardar = await props.GuardarCategoriasMenu(categoriasMenu);
    props.SendMenssageAction(guardar);
  };

  const handleCancelIngredientes = () => {
    setOpenMenuIngredientes(false);
    props.CancelActivarIngredientes();
  };

  if(!props.FSusuarios) return null

  let allUsers = []

  allUsers = props.FSusuarios.filter(user => {
      return user.nombre.toLowerCase().indexOf(searchUsuario.toLowerCase()) !== -1
  })

  if(!profile) return null

  if(profile?.roll === 'cliente'){

    return (
      <ContainerClientes maxWidth="md">
        <ProfileCliente profile={profile} />
      </ContainerClientes>
    )
  }

  return (
    <ContainerClientes maxWidth="md">
      <Card className="card-container">
        <CardTitle title="Clientes" />

        <Box className="burcar-cliente-container">
          <TextField
            type="text"
            label="Buscar cliente"
            variant="outlined"
            name="searchUsuario"
            // value={orderNumber}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#F96302" }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleBuscarUsuario}
            className="input-buscar-usuario"
          />

          <Button variant="contained" className="button-registrar-usuario" onClick={handleOpenRegistrarUsuario}>
            <Typography>Registrar cliente</Typography>
          </Button>
        </Box>

        <Box>
          <Clientes clientes={allUsers} />
        </Box>

      </Card>

      <RegistrarCliente openDrawer={openRegistrarUsuario} handleCloseDrawer={handleCloseRegistrarUsuario} />
    </ContainerClientes>
  );

  //* render
  if (!categoriasMenu || !comercio || !menuIngredientes) return null;
  const { classes, FSIngredientesDisabled } = props;
  function compare(a, b) {
    if (a.nivel < b.nivel) {
      return -1;
    }
    if (a.nivel > b.nivel) {
      return 1;
    }
    return 0;
  }

  // categoriasMenu.sort( compare )
  return (
    <>
      <Grid item className={classes.styleContenedor} xs={12}>
        <Box mt={7.5} />

        <Box>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => setOpenMenuIngredientes(true)}
            disableElevation
          >
            INGREDIENTES
          </Button>

          <Card elevation={0}>
            <Box>
              {FSIngredientesDisabled &&
                FSIngredientesDisabled.map((ingrediente) => (
                  <Fragment key={ingrediente.id}>
                    <Divider />
                    <ListItem ContainerComponent="div">
                      <ListItemIcon className={classes.cursor}>
                        <BlockIcon fontSize="small" color="error" />
                      </ListItemIcon>

                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            {ingrediente.nombre}
                          </Typography>
                        }
                      />

                      <ListItemSecondaryAction>
                        <ListItemText
                          primary={`$ ${parseFloat(ingrediente.costo).toFixed(
                            2
                          )}`}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Fragment>
                ))}
            </Box>
          </Card>
        </Box>

        <Box mb={showGuardarCambios ? 5 : 2}>
          {categoriasMenu &&
            categoriasMenu.map((categoria) => (
              <Categorias key={categoria.id_categoria} categoria={categoria} />
            ))}
        </Box>

        <Box className={classes.fixedDown}>
          {showGuardarCambios ? (
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={handleGuardarCategoriasMenu}
              disableElevation
            >
              GUARDAR CAMBIOS
            </Button>
          ) : null}
        </Box>
      </Grid>

      <Dialog
        open={openMenuIngredientes}
        // TransitionComponent={TransitionSlide}
        // fullScreen
        maxWidth="sm"
        fullWidth
        onClose={handleCancelIngredientes}
        PaperProps={{
          style: {
            backgroundColor: "#eceff1",
            boxShadow: "none",
            height: 780,
          },
        }}
      >
        <Box>
          <ListaIngredientes menuIngredientes={menuIngredientes} />
        </Box>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth, 
  profile: state.firebase.profile, 
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  // GuardarCategoriasMenu: (datos) => dispatch(GuardarCategoriasMenu(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
  // SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(withStyles(styles)(MenuComercio));
import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPOENNTES
import ConfigOptions from './configuraciones/ConfigOptions'
import { AbrirComercio, ActivarServicioDomGuuwii, GuardarComerPassword } from '../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { logout } from '../../store/actions/authActions'
import SoundAlert from '../layout/SoundAlert'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import TextField from '@material-ui/core/TextField'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import StoreIcon from '@material-ui/icons/Store'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Grow from '@material-ui/core/Grow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})


const styles = theme => ({
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff',
        maxWidth: 720,
    },
    regresar: {
        backgroundColor: '#263238',
        cursor: 'pointer',
        color: '#ffffff'
    },    
    listItem: {
        // backgroundColor: '#ffffff',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: "#ffffff",
        },
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
})

const ProfileConfig = (props) => {

    const [comercio, setComercio] = useState(null)
    const [openAbrirComercio, setOpenAbrirComercio] = useState(false)
    const [openServicioDom, setOpenServicioDom] = useState(false)
    const [openPassword, setOpenPassword] = useState(false)
    const [comerPassword, setComerPassword] = useState('')

    useEffect(() => {

        if(props.storageDataReducer.FScomercio){
            setComercio(props.storageDataReducer.FScomercio)
        }

    }, [props.storageDataReducer.FScomercio])

    useEffect(() => {

        setOpenPassword(props.storageDataReducer.FScomercioPassword.openPassword)

    }, [props.storageDataReducer.FScomercioPassword])

    const handleAbrirComercio = async () => {
        props.SendMenssageAction({option: 'inicio'})
        setOpenAbrirComercio(false)
        const abrir = await props.AbrirComercio({abrir: !comercio.abierto})
        props.SendMenssageAction(abrir)       
    }

    const handleServicioDomicilio = async () => {
        props.SendMenssageAction({option: 'inicio'})
        setOpenServicioDom(false)
        await props.ActivarServicioDomGuuwii({activar: !profile.servicioGuuwii})
        props.SendMenssageAction({option: 'reset'})       

    }

    const handleChange = (e) => {

        setComerPassword(e.target.value.trim())
    }

    const handleGuardarComerPassword = async () => {
        props.SendMenssageAction({option: 'inicio'})
        const guardar = await props.GuardarComerPassword(comerPassword)
        props.SendMenssageAction(guardar)
    }

    //*render

    if(!comercio) return null
    const { classes, profile, perfilComercioReducer, setOpenConfiguracion, openConfiguracion } = props
    return (
        <Fragment>
            
            <Dialog 
            open={openConfiguracion} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenConfiguracion(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

                <Box p={1} display="flex" className={classes.regresar} onClick={() => setOpenConfiguracion(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Configuracion
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>


                <Box >
                    <List>
                    <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenAbrirComercio(true)}
                    >
                        <ListItemIcon>
                            <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Comercio ${comercio.abierto ? 'Abierto' : 'Cerrado'}`} />
                        <ListItemSecondaryAction>
                            {perfilComercioReducer.cargandoAbrir ?
                            <FormControlLabel                        
                                control={
                                <CircularProgress color="primary" size={20} />
                                }
                            />
                            : null
                            }
                            <FormControlLabel                        
                                control={
                                    <Switch
                                        checked={comercio.abierto}
                                        // onChange={handleAbrirComercio}
                                    />
                                }
                                label={''}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    </List>
                </Box>


                <Box mt={1}>
                    <ConfigOptions />
                </Box>

                {/* <Box align="center" className={classes.fixedDown}>
                    <Button variant="contained" color="primary" size='large' fullWidth startIcon={<ExitToAppIcon />} onClick={() => props.logout()}>
                            CERRAR SESION
                    </Button>
                </Box> */}

            </Grid> 
            </Dialog>

            <Dialog open={openAbrirComercio} TransitionComponent={Transition} onClose={() => setOpenAbrirComercio(false)} maxWidth={'xs'} fullWidth >
                <Box textAlign="right" bgcolor={'primary.main'} py={1} ></Box>                
                    <Box align="center" p={1}>
                        <Typography variant="h6" className={classes.textColor} >
                        ¿Desea {comercio.abierto ? 'cerrar' : 'abrir'} el comercio?
                        </Typography>
                    </Box>
                <Box py={1} align={'center'}>
                    <Button variant="contained" color="secondary" onClick={handleAbrirComercio} disableElevation>{comercio.abierto ? 'CERRAR' : 'ABRIR'}</Button>
                </Box> 
            </Dialog>

            <Dialog 
            open={openPassword} 
            TransitionComponent={TransitionSlide} 
            maxWidth={'xs'} 
            fullScreen 
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
                <Grid item className={classes.styleContenedor} xs={12}  >
                    <Box p={1} display="flex" className={classes.fixedUp} >
                        <Box flexGrow={1} align={'center'} >
                            <Typography variant="h6" justify="center" >
                                Cambiar Contraseña Administrador
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box px={2} py={2} mt={5}>
                        <Card variant="outlined">
                            <Box my={4}>
                                <Box px={3} my={1}>
                                    <TextField    
                                        color="secondary"                                   
                                        id="contra"
                                        name="contra" 
                                        label="Contraseña"
                                        value={comerPassword}
                                        type="text"                             
                                        onChange={handleChange}
                                        autoComplete="off" 
                                        fullWidth    
                                        />
                                </Box>

                                <Box px={3} mt={2} align="right">
                                    <Button variant="contained" color="secondary" disableElevation onClick={handleGuardarComerPassword}>
                                        Guardar
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>

                </Grid>


            </Dialog>


            <SoundAlert />



            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        profile: state.firebase.profile,     
        storageDataReducer: state.storageDataReducer, 
        perfilComercioReducer: state.perfilComercioReducer,     

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         AbrirComercio: (datos) => dispatch(AbrirComercio(datos)),
         ActivarServicioDomGuuwii: (datos) => dispatch(ActivarServicioDomGuuwii(datos)),
         GuardarComerPassword: (datos) => dispatch(GuardarComerPassword(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
         logout: (datos) => dispatch(logout(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileConfig))

ProfileConfig.propTypes = {
    
}
import React, {useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { StyledBox } from '../../elementos/styled-box/StyledBox';
import { getFecha } from '../../allFunctions'
import { Typography, Box, Card } from '@material-ui/core';
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel';

const CalendarioHorarioContainer = styled(({...props}) => <Box {...props} />)`    

${({ theme }) => `

      width: 350px;

      .drawer-title {
        position: relative;
        padding: 10px;
        text-align: center;
        justify-content: center;
      }

      .close-icon-container {
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px;
        cursor: pointer;
      }
  
      .header-title {
        margin-right: 20px;
      }

      .header-toolbar {
        flex: 1
      }

      .title-dialog-desktop {
        display: flex;
        align-items: center;
      }

      .lugares-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        ${theme.breakpoints.down('sm')} {
          flex-direction: column;
          
        }
      }

      .pilate-cama {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        ${theme.breakpoints.down('sm')} {
        }
      }

      .pilate-cama-selected {
        background-color: #FCF3CF;
      }

      .action-lugar-button {
        margin-top: 20px;
        height: 80px;
        width: 100%;
      }

  `}
`

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function LugaresDrawer2(props) {

  const { fechaCalendarioSelected, horariosSelected, openHorario, handleCloseHorario } = props
  const [selectedLugar, setSelectedLugar] = useState(null)

  const handleSelectLugar = (lugar) => {
    setSelectedLugar(lugar)
  }

  // if(!fechaCalendarioSelected || !horariosSelected) return null

  const fecha  = getFecha(fechaCalendarioSelected?.fecha?.seconds, 5)

  return (
    <div>
          <Drawer anchor={'right'} open={openHorario} onClose={handleCloseHorario}>
            <CalendarioHorarioContainer>
            <Box className='drawer-title'>
                <Typography>
                {fecha}
                </Typography>
                <Typography>
                {horariosSelected?.horario}
                </Typography>

                <Box className='close-icon-container' onClick={handleCloseHorario}>
                  <CancelIcon />  
                </Box>
              </Box>
              <Box className="lugares-container">
                {horariosSelected?.lugares.map(lugar => {

                  let selected = ''
                  if(selectedLugar && selectedLugar?.id === lugar.id){
                    selected = 'pilate-cama-selected'
                  }
                  return (
                    <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} 
                    onClick={() => handleSelectLugar(lugar)}>
                      <Typography>
                        Cama {lugar?.id}
                      </Typography>
                    </Card>
                  )
                  })}

              </Box>

            </CalendarioHorarioContainer>
          </Drawer>
    </div>
  );
}
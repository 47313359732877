import React, { useState, useEffect, Fragment, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import LoginForm from './LoginForm'
import RegisterForm from '../usuarios/registro/RegisterForm'
import Message from '../layout/Message'
import { logout } from '../../store/actions/authActions'

//*MATERIAL UI
import Grow from '@material-ui/core/Grow'
import Zoom from '@material-ui/core/Zoom';
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    card: {
        width: 220,
        height: 40,
        cursor: 'pointer'
    },
    typo: {
        color: '#757575',
    },
    regresar: {
        backgroundColor: '#263238',
        cursor: 'pointer',
        color: '#ffffff'
    },
})

const Login = (props) => {

    useEffect(() => {
        props.logout()
    }, [])
        
   
    //*render
    const { classes } = props

    // return (
    //     <RegisterForm />
    // )

    return (          
        <Fragment>
            <Grow in={true} >
            <Box id="loginForm">
                
               
                <Box >
                <LoginForm />
                </Box>
                
                <Box mt={5} p={3} align='center'>
                    <Typography variant="body2" justify="true">
                    Este sitio está protegido por reCAPTCHA y se aplica la <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidad</a> de Google y los <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Términos de servicio</a>
                    </Typography>
                </Box>

            </Box>
            </Grow>
            
            {/* <Message 
                info={authRegistrarReducer.info}
                warning={authRegistrarReducer.warning}
                error={authRegistrarReducer.error}
                msg={authRegistrarReducer.msg}
                handleCloseMsg={handleCloseMsg}
            /> */}
            
        </Fragment> 
    )
    
}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired   
}


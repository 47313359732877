import { STORAGE_DOMICILIOCOMERCIO_EXITO,
    STORAGE_COMERCIO_EXITO,
    STORAGE_CATEGORIASMENU_EXITO,
    STORAGE_MENUADICIONES_EXITO,
    STORAGE_INGREDIENTES_EXITO,
    STORAGE_INGREDIENTESDISABLED_EXITO,
    SET_NUMPAYMENTSNUEVOS_EXITO,
    STORAGE_MENUSID_EXITO,
    STORAGE_ORDENESFOOD_EXITO,
    STORAGE_MENUBLOQUEADO_EXITO,
    STORAGE_COMISIONFOOD_EXITO,
    STORAGE_REPARTIDORES_EXITO,
    STORAGE_USUARIOS_EXITO,
    STORAGE_CATSOPORTE_EXITO,
    STORAGE_CATMENURESPUESTAS_EXITO,
    STORAGE_PAYMENTINTENTS_EXITO,
    STORAGE_PAGOSHOY_EXITO,
    STORAGE_PAGOS_EXITO,
    STORAGE_TICKETSOPORTEIDS_EXITO,
    STORAGE_ALLTICKETSOPORTE_EXITO,
    STORAGE_ALLORDENESFOOD_EXITO,
    STORAGE_ORDENESNOATENDIDAS_EXITO,
    STORAGE_ORDENESREGRESADAS_EXITO,
    STORAGE_ORDENESCANCELADAS_EXITO,
    STORAGE_ORDENESFINALIZADAS_EXITO,
    STORAGE_COMERCIOPASSWORD_EXITO,
    STORAGE_APPVERSION_EXITO,
    STORAGE_COMENTARIOS_EXITO,
    STORAGE_NUEVOSCOMENTARIOS_EXITO,
    STORAGE_SOUNDSELECTED_EXITO,
    RESET_STATE_STORAGE,
    STORAGE_CONFIG_EXITO,
    STORAGE_LUGARESRESERVADOS_EXITO
} from '../actionTypes'


const initState = {
    FSdomicilioComercio: null,
    FScomercio: null,
    categoriasMenu: [],
    FSmenuAdiciones: null,
    FSmenuIngredientes: null,
    FSIngredientesDisabled: null,
    numPaymentsNuevos: 0,
    menusID: [],
    FSordenesFood: null,
    menuBloqueado: null,
    FScomisionFood: null,
    change: false,
    FSrepartidores: null,
    FSusuarios: null,
    FScatSoporte: null,
    FScatMenuRespuestas: null,
    FSpaymentIntents: null,
    FSpagosHoy: null,
    FSpagos: null,
    FSticketSoporteIDS: [],
    FSallTicketSoporte: null,
    FSallOrdenesFood: null,
    FSallOrdenesNoAtendidas: null,
    FSallOrdenesRegresadas: null,
    FSallOrdenesCanceladas: null,
    FScomercioPassword: {openPassword: false, password: ''},
    FSordenesFinalizadas: null,
    FScomentarios: [],
    FSnuevosComentarios: [],
    FSsoundSelected: 'ring.mp3',
    FSappVersion: null,
    FSconfig: null,
    FSlugaresReservados: null
}

const storageDataReducer = (state = initState, action) => {
    switch (action.type) {
        case STORAGE_DOMICILIOCOMERCIO_EXITO:
            return {
                ...state,
                FSdomicilioComercio: action.payload
                
            }
        case STORAGE_COMERCIO_EXITO:
            return {
                ...state,
                FScomercio: action.payload,
            }
        case STORAGE_CATEGORIASMENU_EXITO:
            return {
                ...state,
                categoriasMenu: action.payload,
                change: !state.change
            }
        case STORAGE_MENUADICIONES_EXITO:
            return {
                ...state,
                FSmenuAdiciones: action.payload,
            }
        case STORAGE_INGREDIENTES_EXITO:
            return {
                ...state,
                FSmenuIngredientes: action.payload,
            }
        case STORAGE_INGREDIENTESDISABLED_EXITO:
            return {
                ...state,
                FSIngredientesDisabled: action.payload,
            }
        case STORAGE_MENUSID_EXITO:
            return {
                ...state,
                menusID: action.payload,
            }
        case STORAGE_ORDENESFOOD_EXITO:
            return {
                ...state,
                FSordenesFood: action.payload,
            }
        case STORAGE_MENUBLOQUEADO_EXITO:
            return {
                ...state,
                menuBloqueado: action.payload
            }
        case STORAGE_COMISIONFOOD_EXITO:
            return {
                ...state,
                FScomisionFood: action.payload
            }
        case STORAGE_REPARTIDORES_EXITO:
            return {
                ...state,
                FSrepartidores: action.payload
            }
        case STORAGE_USUARIOS_EXITO:
            return {
                ...state,
                FSusuarios: action.payload
            }
        case STORAGE_CATSOPORTE_EXITO:
            return {
                ...state,
                FScatSoporte: action.payload
            }
        case STORAGE_CATMENURESPUESTAS_EXITO:
            return {
                ...state,
                FScatMenuRespuestas: action.payload
            }
        case STORAGE_PAYMENTINTENTS_EXITO:
            return {
                ...state,
                FSpaymentIntents: action.payload
            }
        case STORAGE_PAGOSHOY_EXITO:
            return {
                ...state,
                FSpagosHoy: action.payload
            }
        case STORAGE_PAGOS_EXITO:
            return {
                ...state,
                FSpagos: action.payload
            }
        case STORAGE_TICKETSOPORTEIDS_EXITO:
            return {
                ...state,
                FSticketSoporteIDS: action.payload
            }
        case STORAGE_ALLTICKETSOPORTE_EXITO:
            return {
                ...state,
                FSallTicketSoporte: action.payload
            }
        case STORAGE_ALLORDENESFOOD_EXITO:
            return {
                ...state,
                FSallOrdenesFood: action.payload
            }
        case STORAGE_ORDENESNOATENDIDAS_EXITO:
            return {
                ...state,
                FSallOrdenesNoAtendidas: action.payload
            }
        case STORAGE_ORDENESREGRESADAS_EXITO:
            return {
                ...state,
                FSallOrdenesRegresadas: action.payload
            }
        case SET_NUMPAYMENTSNUEVOS_EXITO:
            return {
                ...state,
                numPaymentsNuevos: action.payload
            }
        case STORAGE_ORDENESCANCELADAS_EXITO:
            return {
                ...state,
                FSallOrdenesCanceladas: action.payload
            }
        case STORAGE_ORDENESFINALIZADAS_EXITO:
            return {
                ...state,
                FSordenesFinalizadas: action.payload
            }
        case STORAGE_COMENTARIOS_EXITO:
            return {
                ...state,
                FScomentarios: action.payload
            }
        case STORAGE_NUEVOSCOMENTARIOS_EXITO:
                return {
                    ...state,
                    FSnuevosComentarios: action.payload
                }
        case STORAGE_COMERCIOPASSWORD_EXITO:
            return {
                ...state,
                FScomercioPassword: action.payload
            }
        case STORAGE_SOUNDSELECTED_EXITO:
            return {
                ...state,
                FSsoundSelected: action.payload
            }
        case STORAGE_APPVERSION_EXITO:
            return {
                ...state,
                FSappVersion: action.payload
            }
        case RESET_STATE_STORAGE:
            return {
                ...state,
                FSrepartidores: action.payload
            }
        case STORAGE_CONFIG_EXITO:
            return {
                ...state,
                FSconfig: action.payload
            }
        case STORAGE_LUGARESRESERVADOS_EXITO:
            return {
                ...state,
                FSlugaresReservados: action.payload
            }
        default:
        return state
    }
}

export default storageDataReducer
import { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Drawer, Typography } from "@material-ui/core"
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";

import styled from 'styled-components'

import { ResetPasswordCliente } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";
import { DrawerTitle } from "../../elementos/drawer-title";



const ContainerPasswordCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  width: 350px;
  position: relative;
  height: 100%;
  padding: 10px;
  margin-top: 10px;

  .input-form-user {
    margin-bottom: 20px;
  }

  .cliente-nombre {
    text-align: center;
    margin-bottom: 10px;
  }

  .button-password-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .action-password-button {
    height: 80px;
    margin-bottom: 20px;
  }

`}
`


const ClientePassword = (props) => {

  const { openDrawer, selectedCliente } = props

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleCloseDrawer = () => {
    setPassword('')
    setConfirmPassword('')
    props.handleCloseDrawer()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const valor = e.target.value

    if(name === 'password'){
      setPassword(valor)
    }
    if(name === 'confirmPassword'){
      setConfirmPassword(valor)
    }

  }

  const handleChangePassword = () => {

  }

  return (
    <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <DrawerTitle title={'Cambiar contraseña'} handleCloseDrawer={handleCloseDrawer} />
        <ContainerPasswordCliente>



          <TextField
            label="Contraseña"
            name="password"
            value={password}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
         
          <TextField
            label="Confirmar contraseña"
            name="confirmPassword"
            value={confirmPassword}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />

          <Box className='button-password-container'>

          <Typography className="cliente-nombre">
              {`${selectedCliente?.nombre} ${selectedCliente?.apellidos}`}
            </Typography>

            <Button fullWidth className='action-password-button' variant='contained' onClick={handleChangePassword} >
                Cambiar contraseña
            </Button>
          </Box>

        </ContainerPasswordCliente>
      </Drawer>
  )
}

const mapStateToProps = (state) => ({
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  ResetPasswordCliente: (datos) => dispatch(ResetPasswordCliente(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(ClientePassword);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { getFecha } from '../../allFunctions'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Card from '@material-ui/core/Card'
import LugaresDrawer from './LugaresDrawer'
import { Container } from '@material-ui/core';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion"
import { ContainerApp } from '../../App'

import { yellow, green, grey } from '@material-ui/core/colors'

const CalendarioHorarioContainer = styled(({...props}) => <Box {...props} />)`    

${({ theme }) => `

.dialog-container {
  z-index: 1 !important;
}

.horarios-container {
  width: 100%;
}

.dialog-title-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${yellow["A400"]};
  border-radius: 0px 00px 8px 8px;
}

.calendar-card-container {
  height: 100%;
  border-radius: 15px 15px 10px 10px;
}

.motion-horario-container {
  width: calc(100% / 3 - 20px);
  height: 150px;
  border: none;
  cursor: pointer;
  margin: 10px;
  border-radius: 15px 15px 10px 10px;
  padding: 0px;
  ${theme.breakpoints.down('sm')} {
    height: 80px;
  }
}

.calendar-disponibilidad {
  background-color: ${yellow["A700"]};
  color: ${grey[900]};
  font-size: 18px;
  ${theme.breakpoints.down('sm')} {
    font-size: 11px;
  }

}
.calendar-disponibilidad-num {
  background-color: ${yellow["A700"]};
  color: ${grey[900]};
  font-size: 20px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    font-size: 13px;
  }

}

.calendar-horario-container {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    height: 40px;
  }
}

.calendar-horario {
  font-size: 22px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
}

.dialog-title {
  font-size: 24px;
  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
  }
}

.calendar-horario-selected {
  color: ${green["300"]};
}
   

`}
`

const CalendarioHorarios = (props) => {

  const { fechaCalendarioSelected, openDayTimeDialog, handleCloseDayTimeDialog, handleOpenHorario, auth } = props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  if(!fechaCalendarioSelected) return null
  const fecha  = getFecha(fechaCalendarioSelected?.fecha?.seconds, 5)

  return (
      <Dialog 
        fullScreen
        open={openDayTimeDialog} 
        onClose={handleCloseDayTimeDialog} 
        className='dialog-container'
        >
        <ContainerApp>
        <CalendarioHorarioContainer>
        {isMobile ?
        <Box className='dialog-title-container'>
          <Box ml={2}>
            <IconButton onClick={handleCloseDayTimeDialog}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box ml={2}>
            <Typography className="dialog-title">
              {fecha}
            </Typography>
          </Box>
      </Box>
        :
        null
        }

        <Container maxWidth="md">

          {isMobile ?null
          :
          <Box className='dialog-title-container'>

            <Box ml={2}>
              <IconButton onClick={handleCloseDayTimeDialog}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box ml={2}>
              <Typography className='dialog-title '>
                {fecha}
              </Typography>
            </Box>

          </Box>
          }

          <Box className="horarios-container">
                {fechaCalendarioSelected && fechaCalendarioSelected.horarios.map(horario => {

                  const disponbles= horario.lugares.filter(lugar => lugar.ocupado === false)
                  
                  const horariosSelected = horario.lugares.find(lugar => lugar?.idCliente === auth?.uid)
                  let selected = ''
                  if(horariosSelected){
                    selected = 'calendar-horario-selected'
                  }

                  return (
                    <motion.button
                      key={horario?.id}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="motion-horario-container"
                      onClick={() => handleOpenHorario(horario)}
                    >
                    <Card className="calendar-card-container" variant="outlined">
                        <Typography className='calendar-disponibilidad'>
                        disponibles
                        </Typography>
                        <Typography className='calendar-disponibilidad-num'>
                        {disponbles?.length}
                        </Typography>
                      <Box className="calendar-horario-container" >
                        <Box>
                          <Typography className={`calendar-horario ${selected}`} >
                            {getFecha(horario?.id, 4)}
                          </Typography>
                        </Box>
                      </Box>
                      </Card>
                    </motion.button>
                  )
                })}

              </Box>

        </Container>          
        </CalendarioHorarioContainer>
        </ContainerApp>
        </Dialog>
  );
}


const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FScalendarioData: state.calendarioReducer.calendarioData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // UpdateCalendarioFecha: (datos) => dispatch(UpdateCalendarioFecha(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarioHorarios)
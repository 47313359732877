import { getFecha } from '../../allFunctions'
function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
      const fecha = new Date(date)
      days.push(fecha);
      date.setDate(date.getDate() + 1);

  }
  return days;
}




export const ResetPasswordCliente = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {



  }
}


export const EditarClienteAction = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
      exito: false,
      option: '',
      message: ''
  }

  const firestore = getFirestore()  

  await firestore.collection('usuarios').doc(data?.id).update(data)
  .then(() => {
    resultado.exito = true
    resultado.option = 'reset'
  })
  .catch(error => {
    console.log('error', error)
  })

  return resultado


  }
}


export const ComprarCreditosCliente = ({cliente, paquete}) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    const firestore = getFirestore()  

    const creditos = parseInt(cliente?.creditos) + parseInt(paquete?.creditos)

    await firestore.collection('usuarios').doc(cliente?.id).update({
      creditos: creditos,
    })
    .then(() => {
      resultado.exito = true
      resultado.option = 'reset'
    })
    .catch(error => {
      console.log('error', error)
    })

    return resultado

  }
}


export const UpdateCalendarioFecha = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    const dias = [
      {
        id: 0,
        dia: 'Domingo',
        horarios: [
          '0:0',
        ]
      },
      {
        id: 1,
        dia: 'Lunes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:15',
          '19:30'
        ]
      },
      {
        id: 2,
        dia: 'Martes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:15',
          '19:30'
        ]
      },
      {
        id: 3,
        dia: 'Miercoles',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:15',
          '19:30'
        ]
      },
      {
        id: 4,
        dia: 'Jueves',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:15',
          '19:30'
        ]
      },
      {
        id: 5,
        dia: 'Viernes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:0',
        ]
      },
      {
        id: 6,
        dia: 'Sabado',
        horarios: [
          '9:15',
          '10:15'
        ]
      }
    ]

    const lugares = [
      {
          id: 1,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 2,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 3,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 4,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 5,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 6,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
  ]

  const paquetes = [
    {
      creditos: '1',
      costo: '200',
      vigencia: 5
    },
    {
      creditos: '5',
      costo: '900',
      vigencia: 15
    },
    {
      creditos: '10',
      costo: '1700',
      vigencia: 20
    },
    {
      creditos: '15',
      costo: '2300',
      vigencia: 25
    },
    {
      creditos: '20',
      costo: '2850',
      vigencia: 30
    },
  ]

    const firestore = getFirestore()

    await firestore.collection('config').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
      paquetes: paquetes
    })
    .then(() => {
      resultado.exito = true
      resultado.option = 'reset'
    })
    .catch(error => {
      console.log('error', error)
    })

    return



    const batch = firestore.batch()
    const calendarioRef = firestore.collection('calendario')

    const allDays = getDaysInMonth(1, 2023)

    console.log('allDays', allDays.length)

    const allIdDocuments = []
    allDays.map(fecha => {

      const year = fecha.getFullYear()
      const month = parseInt(fecha.getMonth()) + 1
      const day = fecha.getDate()

      const idDocument = `${year}${month <= 9 ? '0'+month : month}${day <= 9 ? '0'+day : day}`
      allIdDocuments.push(idDocument)
      console.log(idDocument, '- dia: ', fecha.getDate())
      console.log(fecha, '- nomnreDia: ', dias[fecha.getDay()])

      const horariosData = dias[fecha.getDay()]

      const horariosDoc = []

      // const horariosRef = firestore.collection('calendario').doc(idDocument).collection('horarios')
      horariosData?.horarios && horariosData?.horarios.map(horarios => {
        const horario = horarios.split(':')
        // console.log('horarioTT', horario)
        fecha.setHours(0)
        fecha.setMinutes(0)
        fecha.setHours(fecha.getHours() + horario[0]);
        fecha.setMinutes(fecha.getMinutes() + horario[1]);
        const seconds = fecha.getTime() / 1000;
        console.log('secondsTT', seconds)
        console.log('secondsTT', getFecha(seconds, 3))

        horariosDoc.push({
          id: seconds,
          disponible: true,
          lugares: lugares
        })


        // batch.set(horariosRef.doc(seconds.toString()), {
        //   disponible: true,
        //   lugares: lugares
        // })
        return true
      })

      fecha.setHours(0)
      fecha.setMinutes(0)
      batch.set(calendarioRef.doc(idDocument), {
        fecha: fecha,
        disponible: true,
        horarios: horariosDoc
      })
      
      return true
    })

    // return

    await batch.commit()
    .then(() => {
        resultado.exito = true
        resultado.option = 'reset'
        console.log('exito!!')
        return resultado
    })
    .catch(error => {
        console.log('error', error)
        resultado.option = 'error'
        resultado.message = 'Se produjo un error'
    })
       
    

    // const id = calendarioRef.doc().id

   


    // await firestore.collection('calendario').doc('2023125').update({
    //   fecha: new Date(),
    //   horarios: datos,
    // })
    // .then(() => {
    //     return resultado
    // })
    // .catch(error => {
    //     console.log(error)
    //     resultado.option = 'error'
    //     resultado.message = 'Se produjo un error'
    // })

    // return resultado

  }
}


export const SetReservePlaceDateByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }
    console.log('dataReserve', data)

    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const lugaresReservadosRef = firestore.collection('lugaresReservados').doc()
    const usuariosRef = firestore.collection('usuarios').doc(cliente?.id)

    const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        console.log('super entro', sfDoc.data());
        const calendarioHorarios = sfDoc.data()
        let horarioSeconds
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){
            console.log('entro horario')
            horario?.lugares.map(lugar => {
              console.log('entro lugar', idLugar)
              if(lugar?.id === idLugar && !lugar.ocupado){
                console.log('entro lugar editar')
                lugar.ocupado = true
                lugar.idCliente = cliente?.id
                lugar.cliente = `${cliente?.nombre} ${cliente?.apellidos}`
                horarioSeconds = horario?.id
              }
              return lugar
            })
          }
          return horario
        })

        // Add one person to the city population.
        // Note: this could be done without a transaction
        //       by updating the population using FieldValue.increment()
        // var newPopulation = sfDoc.data().population + 1;

        const batch = firestore.batch()

        // batch.update(calendarioRef, {
        //     horarios: horarios
        // })
        // batch.update(calendarioRef, { horarios: horarios });

        batch.update(usuariosRef, {
          creditos: decrement
        })

        const fecha = new Date(1970, 0, 1); // Epoch
        fecha.setSeconds(horarioSeconds);

        batch.set(lugaresReservadosRef, {
          fecha: fecha,
          idFecha: idFecha,
          idHorario: idHorario,
          idLugar: idLugar,
          idCliente: cliente?.id

        })
        
        let success = true
        await batch.commit()
        .then(() => {
          console.log('batch exito')
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
          console.log('error batch', error)
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
          success = false
        })

        if(success){
          transaction.update(calendarioRef, { horarios: calendarioHorarios?.horarios });
        }

      });





      const calendarioData = await transaction.get(calendarioRef)







      if(calendarioData?.exists){
          console.log('super entro', calendarioData.data());
          const calendarioHorarios = calendarioData.data()
          const horarios = calendarioHorarios?.horarios
          horarios && horarios.map(horario => {
            if(horario?.id === idHorario){
              console.log('entro horario')
              horario?.lugares.map(lugar => {
                console.log('entro lugar', idLugar)
                if(lugar?.id === idLugar && !lugar.ocupado){
                  console.log('entro lugar editar')
                  lugar.ocupado = true
                  lugar.idCliente = cliente?.id
                  lugar.cliente = `${cliente?.nombre} ${cliente?.apellidos}`
                }
                return lugar
              })
            }
            return horario
          })

          console.log('paso', horarios)

          const batch = firestore.batch()

          // batch.update(calendarioRef, {
          //   horarios: horarios
          // })


          console.log('decrement',decrement)

          batch.update(usuariosRef, {
            creditos: decrement
          })

          batch.set(lugaresReservadosRef, {
            idFecha: idFecha,
            idHorario: idHorario,
            idLugar: idLugar,
            idCliente: cliente?.id

          })

          await batch.commit()
          .then(() => {
            console.log('batch exito')
              resultado.exito = true
              resultado.option = 'reset'
              return resultado
          })
          .catch(error => {
              console.log('error batch', error)
              resultado.option = 'error'
              resultado.message = 'Se produjo un error'
          })

      }

    })
    .then(result => {
        console.log('Transaction success!', result);
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.option = 'error'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const SetCancelReservePlaceDateByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const FSlugaresReservados = getState().storageDataReducer.FSlugaresReservados
    console.log('dataReserve', data)

    const lugarReservado = FSlugaresReservados.find(lugReservado => lugReservado?.idFecha === idFecha && lugReservado?.idLugar === idLugar && lugReservado?.idCliente === cliente?.id)

    console.log('lugarReservado', lugarReservado)
    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const lugaresReservadosRef = firestore.collection('lugaresReservados').doc(lugarReservado?.id)
    const usuariosRef = firestore.collection('usuarios').doc(cliente?.id)

    const increment = firestore.FieldValue.increment(1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        console.log('super entro', sfDoc.data());
        const calendarioHorarios = sfDoc.data()
        const horarios = calendarioHorarios?.horarios
        horarios && horarios.map(horario => {
          if(horario?.id === idHorario){
            console.log('entro horario')
            horario?.lugares.map(lugar => {
              console.log('entro lugar', idLugar)
              if(lugar?.id === idLugar && lugar.ocupado && lugar?.idCliente === cliente?.id){
                console.log('entro lugar editar')
                lugar.ocupado = false
                lugar.idCliente = ''
                lugar.cliente = ''
              }
              return lugar
            })
          }
          return horario
        })

        // Add one person to the city population.
        // Note: this could be done without a transaction
        //       by updating the population using FieldValue.increment()
        // var newPopulation = sfDoc.data().population + 1;

        const batch = firestore.batch()

        // batch.update(calendarioRef, {
        //     horarios: horarios
        // })
        // batch.update(calendarioRef, { horarios: horarios });

        batch.update(usuariosRef, {
          creditos: increment
        })

        batch.delete(lugaresReservadosRef)
        
        let success = true
        await batch.commit()
        .then(() => {
          console.log('batch exito')
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
          console.log('error batch', error)
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
          success = false
        })

        if(success){
          transaction.update(calendarioRef, { horarios: horarios });
        }

      });





      const calendarioData = await transaction.get(calendarioRef)







      if(calendarioData?.exists){
          console.log('super entro', calendarioData.data());
          const calendarioHorarios = calendarioData.data()
          const horarios = calendarioHorarios?.horarios
          horarios && horarios.map(horario => {
            if(horario?.id === idHorario){
              console.log('entro horario')
              horario?.lugares.map(lugar => {
                console.log('entro lugar', idLugar)
                if(lugar?.id === idLugar && !lugar.ocupado){
                  console.log('entro lugar editar')
                  lugar.ocupado = true
                  lugar.idCliente = cliente?.id
                  lugar.cliente = `${cliente?.nombre} ${cliente?.apellidos}`
                }
                return lugar
              })
            }
            return horario
          })

          console.log('paso', horarios)

          const batch = firestore.batch()

          // batch.update(calendarioRef, {
          //   horarios: horarios
          // })


          console.log('decrement',increment)

          batch.update(usuariosRef, {
            creditos: increment
          })

          batch.set(lugaresReservadosRef, {
            idFecha: idFecha,
            idHorario: idHorario,
            idLugar: idLugar,
            idCliente: cliente?.id

          })

          await batch.commit()
          .then(() => {
            console.log('batch exito')
              resultado.exito = true
              resultado.option = 'reset'
              return resultado
          })
          .catch(error => {
              console.log('error batch', error)
              resultado.option = 'error'
              resultado.message = 'Se produjo un error'
          })

      }

    })
    .then(result => {
        console.log('Transaction success!', result);
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.option = 'error'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const RegistrarClienteAction = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    console.log('data cliente', data)
    const firestore = getFirestore()
    const firebase = getFirebase()   

    const funct = firebase.functions()
    const createUserAccount = funct.httpsCallable('admon_CrearCuentaUsuario')

    data.usuario = 'joserios'
    data.password = '123456'
    await createUserAccount(data)
        .then(response => {
          console.log('response', response)
          if(response?.data?.exito){

            resultado.exito = true
            resultado.option = 'reset'
          }else{
            resultado.option = 'error'
            resultado.message = response?.data?.message
          }

          return resultado
      })
      .catch(error => {
          console.log('error', error);
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
      })

    // await firestore.collection('usuarios').doc(data.id_comercio).collection('categoriasMenu').add({
    //   ...data,
    //   createAt: new Date()
    // })
    // .then(doc => {
    //     resultado.exito = true
    //     resultado.option = 'reset'
    // })
    // .catch(error => {
    //     // console.log(error);
    //     resultado.option = 'error'
    //     resultado.message = 'Se produjo un error'
    // })

    
    // resultado.message = 'Cambios realizados con exito!!'



    return resultado

  }
}
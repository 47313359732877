
import { Box, Button, Card, Typography } from '@material-ui/core';


export const LugaresCliente = (props) => {

  const { horariosSelected, selectedCliente, selectedLugar, handleSelectLugar, handleSetLugarHorario, handleCancelReservation, hasReserve } = props

  let buttonColor = ''

  if(selectedLugar){
    buttonColor = 'lugar-button-reservar'
  }else if(hasReserve){
    buttonColor = 'lugar-button-cancelar'
  }

  return (
    <>
      <Box className="lugares-container">
        {horariosSelected?.lugares.map(lugar => {

          let selected = ''
          if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
            console.log('lugar', lugar)
            selected = 'pilate-camaocupado-cliente'
          }else if(lugar?.ocupado){
            selected = 'pilate-cama-ocupado'

          }else if(selectedLugar && selectedLugar?.id === lugar.id){
            selected = 'pilate-cama-selected'
          }
          return (
            <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} onClick={lugar?.ocupado || hasReserve ? null : () => handleSelectLugar(lugar)}>
              <Typography>
                Cama {lugar?.id}
              </Typography>
            </Card>
          )
        })}

        </Box>

        <Box className='button-lugar-container'>
          <Button fullWidth className={`action-lugar-button`} 
          color={selectedLugar ? 'primary' : hasReserve ? 'secondary' : 'default'}
          variant='contained' 
          onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
          disabled={!selectedLugar && !hasReserve}
          >
            {selectedLugar ? 'Reservar' : hasReserve ? 'Cancelar' : ''}
          </Button>
        </Box>
    </>
  )
}
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import { ReCaptcha  } from 'react-recaptcha-v3'
import PropTypes from 'prop-types'

//*COMPONENTES
import { login, resetStateLogin, MessageAuthActions } from '../../store/actions/authActions'
import { onlyLettersNumbers } from '../../allFunctions'

import ErrorMensaje from '../toolsFunctions/ErrorMensaje'
import Spinner3 from '../layout/Spinner3'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock'
import Card from '@material-ui/core/Card'


import { withStyles } from '@material-ui/styles'

const styles = {
    styleContenedor: {
    margin: 'auto',
    maxWidth: 400,
    },
    styleError : {
        backgroundColor: '#FFF9C4',
        color: 'red'
    },
    card: {
        width: 220,
        height: 40,
        cursor: 'pointer'
    },
    typo: {
        color: '#757575',
    },
}

const initState = {
    usuario: '',
    password: '',
    showPassword: false,
    error: null,       
    recaptcha: null 
}

const LoginForm = (props) => {
    
    const [usuario, setUsuario] = useState(initState)

    const refFocus = useRef()

    useEffect(() => {
        setUsuario(initState)
        props.resetStateLogin()

    }, [props.openLogin])
    

    const verifyCallback = async (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!        
        if(recaptchaToken ){ 
            await props.login(usuario, recaptchaToken)            
        }
    }

    const handleChange = (e) => {

        if(e.target.name === 'usuario'){
            if(onlyLettersNumbers(e.target.value)){

                setUsuario({
                    ...usuario,
                    [e.target.name] : e.target.value.trim().toLowerCase()
                })
            }else{
                props.MessageAuthActions()
            }
            
        }else{

            setUsuario({
                ...usuario,
                [e.target.name] : e.target.value
            })
        }

    }

    const handleClickShowPassword = () => {
        setUsuario({ 
            ...usuario,
            showPassword: !usuario.showPassword 
        })
    }
    
    const handleMouseDownPassword = (e) => {
        e.preventDefault()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // recaptcha.execute()
        refFocus.current.focus()
        await props.login(usuario)
    }

    //*render
    const { loginUser, classes } = props
    const error = loginUser.loginError ? <ErrorMensaje mensaje={loginUser.mensajeError} /> : null
    const cargar = loginUser.cargar  
    const logueado = loginUser.login ? null : null 
    return (
        
        <Fragment> 
            {logueado}              
            <Grid item xs={12} className={classes.styleContenedor} >

                <Box align={'center'} p={2} >
                    <Typography variant="h3">
                        Movement Studio
                    </Typography>                   
                </Box>
                
                <form
                onSubmit={handleSubmit}
                >
                    <Box px={6} my={1}>
                        <TextField    
                        color="secondary"                                   
                        id="usuario"
                        name="usuario" 
                        label="Usuario"
                        value={usuario.usuario}
                        type='text'                             
                        onChange={handleChange}
                        autoComplete="off" 
                        fullWidth 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <PersonIcon />
                                </InputAdornment>
                            ),
                            }}                               
                        />
                    </Box>
                
                    <Box px={6} >
                        <TextField
                        color="secondary" 
                        id="password"
                        name="password" 
                        label="Contraseña "
                        value={usuario.password}
                        type={usuario.showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            // endAdornment:
                            //         <InputAdornment position="end">
                            //             <IconButton
                            //             aria-label="toggle password visibility"
                            //             onClick={handleClickShowPassword}
                            //             onMouseDown={handleMouseDownPassword}
                            //             >
                            //             {usuario.showPassword ? <Visibility /> : <VisibilityOff />}
                            //             </IconButton>
                            //         </InputAdornment>
                        }}
                        autoComplete="off"
                        fullWidth                              
                        />
                    </Box>
                    
                    {error}
                    
                    <Box p={2} align={'center'} className="" >
                        {cargar ?
                            <Spinner3 />
                        : 
                            <Button type="submit" variant="contained" color="primary" ref={refFocus} >Iniciar Sesion</Button>                                
                        }                            
                    </Box>

                    <ReCaptcha
                        // ref={ ref => recaptcha = ref }
                        sitekey="6Ld7gsQUAAAAAMIgSEWkhT-Whtrx9OxVu3Cnsurm"
                        data-size="invisible"
                        data-theme="dark"            
                        render="explicit"
                        action='action_login'
                        verifyCallback={verifyCallback}                                   
                    />  
                </form>
        
            </Grid>
        </Fragment>
    )
    
}

const mapStateToProps = (state) => {
    return{
        loginUser : state.loginUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        login: (credentials, recaptchaToken) => dispatch(login(credentials, recaptchaToken)),
        MessageAuthActions: () => dispatch(MessageAuthActions()),
        resetStateLogin: () => dispatch(resetStateLogin())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginForm))

LoginForm.propTypes = {
    loginUser: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    resetStateLogin: PropTypes.func.isRequired    
}
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types'

//*COMPONENTES
import PaymentIntent from './PaymentIntent'
import { ActivarSoundAlert } from '../../store/actions/perfilComercioActions'
import { SetNumPaymentsNuevos } from '../../store/actions/storageDataAction'
import { ActualizarPaymentVisto, ActualizarPaymentAtendido } from '../../store/actions/ordenPedidosActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { telFormat, getFecha } from '../../allFunctions'
import Horarios from './Horarios'
import DiasSelect from './DiasSelect'
//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import { Box, Container, Card, Divider, Drawer } from '@material-ui/core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 720,
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        }      
    },
})

const payment = []

const Ordenes = (props) => {

    const [inicializado, setInicializado] = useState(false)
    const [openPayment, setOpenPayment] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [paymentIntentData, setPaymentIntentData] = useState(null)
    const [allpaymentIntents, setAllPaymentIntents] = useState(null)

    useEffect(() => {

        if(!inicializado){
            inicializar()
            return
        }
        
        if(props.FSpaymentIntents){
            const paymentIntents = []
            props.FSpaymentIntents.map(paymentIntent => {

                if(paymentIntent.status < 2){
                    payment.push(paymentIntent.id)
                    paymentIntents.push(paymentIntent)
                }

            })

            setAllPaymentIntents(paymentIntents)
        }

    }, [props.FSpaymentIntents, inicializado])

    const handleOpenConfig = (option) => {
        setOpenConfig(true)
    }
    const handleCloseConfig = () => {
        setOpenConfig(false)
    }

    const inicializar = async () => {

        await new Promise(resolve => setTimeout( resolve , 5000))  
        setInicializado(true)
    }

  
    const requestPermission = () => {
        if (!('Notification' in window)) {
          alert('Notification API not supported!');
          return;
        }
        
        Notification.requestPermission(function (result) {
          console.log('result',result)
          
        });
    }

    const nonPersistentNotification = () => {
        if (!('Notification' in window)) {
            alert('Notification API not supported!');
            return;
        }
        
        try {
            var notification = new Notification("Nueva Orden Recibida!");
        } catch (err) {
            alert('Notification API error: ' + err);
        }
    }
      

    const handleOpenPayment = (paymentData) => {

        if(!paymentData.visto){
            props.ActualizarPaymentVisto({id: paymentData.id})
        }
        
        setPaymentIntentData(paymentData)
        setOpenPayment(true)
        
    }

    const handleActualizarPaymentAtendido = async (id) => {

        props.SendMenssageAction({option: 'inicio'})
        const actualizar = await props.ActualizarPaymentAtendido({id: id})

        if(actualizar.exito){
            setOpenPayment(false)
            setPaymentIntentData(null)
        }
        props.SendMenssageAction(actualizar)
    }

    const handleClosePayment = () => {

        setOpenPayment(false)
        setPaymentIntentData(null)
        
    }

    //*render

    return (
        <Container maxWidth="md">
            <Card>
                <Box mt={20}>
                    <Typography>
                        Configuraciones
                    </Typography>
                </Box>
                <List>
                    <ListItem button onClick={() => handleOpenConfig('horarios')}>
                        <ListItemIcon>
                            <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Horaros" />
                    </ListItem>
                    <Divider />
                </List>

            </Card>

            <Drawer
                anchor={'right'}
                open={openConfig}
                onClose={handleCloseConfig}
            >
            <Horarios />
            <DiasSelect />
          </Drawer>
        </Container>
    )
    if(!allpaymentIntents) return null
    const { classes } = props

    let count

    count = allpaymentIntents.length
    
    return (
        <Fragment>
             <Grid item className={classes.styleContenedor} xs={12}  >

                <Box>
                    {allpaymentIntents && allpaymentIntents.map(FSpaymentIntent => {

                        return(
                            <Box p={1} key={FSpaymentIntent.id}>
                                <Card variant='outlined' style={{backgroundColor: FSpaymentIntent.status === 0 ? '#fff' : '#b2dfdb', cursor: 'pointer', marginTop: 20}} onClick={() => handleOpenPayment(FSpaymentIntent)}>
                                    <Box minHeight={100}>
                                        <Box textAlign='center' style={{backgroundColor: '#000'}}>
                                            <Typography style={{color: '#fff', fontSize: '16px'}}>
                                                ID: <span>{FSpaymentIntent.id_orden.toUpperCase().slice(0, 7)}</span><span style={{color: '#00BCD4'}}>{FSpaymentIntent.id_orden.toUpperCase().slice(7, 11)}</span>
                                            </Typography>
                                        </Box>
                                        <Box px={1} display='flex'>
                                            <Box flexGrow={1}>
                                            <Typography style={{fontSize: '16px', color: '#000'}}>
                                                asd
                                            </Typography>
                                            </Box>
                                            <Box>
                                            <Typography style={{fontSize: '16px'}}>
                                            {getFecha(FSpaymentIntent.createdAt.seconds, 2)}
                                            </Typography>
        
                                            </Box>
                                        </Box>
                                        {/* <Box px={1}>
                                            <Typography style={{fontSize: '16px'}}>
                                                {FSpaymentIntent.domicilio}
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                {telFormat(FSpaymentIntent.telefono)}
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                $ {parseFloat(FSpaymentIntent.total).toFixed(2)}
                                            </Typography>
                                        </Box> */}
                                    </Box>
                                </Card>
                            </Box>
                        )
                    })}
                    
                </Box>

            </Grid>

            <Dialog 
            open={openPayment}
            // TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            onClose={handleClosePayment}
            >
                <Box>
                   {/* {paymentIntentData ? 

                   <PaymentIntent 
                   paymentIntent={paymentIntentData}
                   handleActualizarPaymentAtendido={handleActualizarPaymentAtendido}
                   />
                   :
                   null
                   } */}
                   
                </Box>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        FSpagosHoy: state.storageDataReducer.FSpagosHoy,
        FSpaymentIntents: state.storageDataReducer.FSpaymentIntents,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarSoundAlert: (datos) => dispatch(ActivarSoundAlert(datos)),
         SetNumPaymentsNuevos: (datos) => dispatch(SetNumPaymentsNuevos(datos)),
         ActualizarPaymentVisto: (datos) => dispatch(ActualizarPaymentVisto(datos)),
         ActualizarPaymentAtendido: (datos) => dispatch(ActualizarPaymentAtendido(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ordenes))

Ordenes.propTypes = {
    
}